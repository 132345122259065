import { VERSION_STATUSES } from '@/constants/index.js';

export const solutionsStructureGetterTypes = Object.freeze({
    GET_SOLUTIONS_STRUCTURE: '[structures] get structure',
    GET_CURRENT_VERSION: '[structures] get current version',
    GET_CURRENT_VERSION_IS_PUBLISHED: '[structures] get current version is published',
    GET_LOADING: '[structures] get loading',
    GET_ERROR: '[structures] get error',
    GET_REMOVED_MODULE_INDEX: '[structure] get removed module index',
    GET_REMOVED_SECTION_INDEX: '[structure] get removed section index',
    GET_REMOVED_PAGE_INDEX: '[structure] get removed page index',
    GET_MODULE: '[structure] get module',
    GET_IS_MODULE_CRETED: '[structure] is module in creation process',
    GET_SOLUTION_IS_PAGE: '[structure] get solution is page',
    GET_SOLUTION_IS_COMPLETED: '[structure] get solution is completed',
    GET_PASSAGE_FORMAT: '[structure] get passage format',
    GET_ACCESS_DATA_FOR_RESTORE: '[structure] get access data for restore',
    GET_PROJECT_WITH_STRUCURE: '[structure] get project with structure',
    GET_STRUCTURE_REQUIRED_BLOCKS: '[structure] get structure required blocks',
    GET_LAST_MODULE_CREATED_ID: '[structure] get last module created id',
});

export const solutionsStructureGetters = {
    [solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE]: state => state.structure ?? {},
    [solutionsStructureGetterTypes.GET_CURRENT_VERSION]: state => state.structure?.version ?? {},
    [solutionsStructureGetterTypes.GET_CURRENT_VERSION_IS_PUBLISHED]: state => state.structure?.version?.status === VERSION_STATUSES.PUBLISHED ?? false,
    [solutionsStructureGetterTypes.GET_LOADING]: state => state.loading,
    [solutionsStructureGetterTypes.GET_ERROR]: state => state.error,
    [solutionsStructureGetterTypes.GET_REMOVED_MODULE_INDEX]: state => state.removedModuleIndex,
    [solutionsStructureGetterTypes.GET_REMOVED_SECTION_INDEX]: state => state.removedSectionIndex,
    [solutionsStructureGetterTypes.GET_REMOVED_PAGE_INDEX]: state => state.removedPageIndex,
    [solutionsStructureGetterTypes.GET_MODULE]: state => id => state.structure?.modules?.find?.(item => item.id === id) ?? {},
    [solutionsStructureGetterTypes.GET_IS_MODULE_CREATED]: state => state.structure.modules?.some(item => item.tempId) ?? [],
    [solutionsStructureGetterTypes.GET_SOLUTION_IS_PAGE]: state => {
        const modules = state.structure.modules ?? [];

        return modules?.length === 1 && !modules[0]?.with_sections && modules[0]?.pages?.length === 1;
    },
    [solutionsStructureGetterTypes.GET_SOLUTION_IS_COMPLETED]: state => state.structure?.version?.completion_type === 'completed',
    [solutionsStructureGetterTypes.GET_PASSAGE_FORMAT]: state => state.structure?.version?.passage_format,
    [solutionsStructureGetterTypes.GET_ACCESS_DATA_FOR_RESTORE]: state => state.accessDataForRestore,
    [solutionsStructureGetterTypes.GET_PROJECT_WITH_STRUCURE]: state => state.has_structure,
    [solutionsStructureGetterTypes.GET_LAST_MODULE_CREATED_ID]: state => state.lastModuleCreatedId,
    [solutionsStructureGetterTypes.GET_STRUCTURE_REQUIRED_BLOCKS]: state => {
        const allPages = state.structure?.modules?.map(module => {
            if (module.with_sections) {
                return module.sections.map(section => section.pages).flat();
            }

            return module.pages;

        }).flat();

        return Boolean(allPages?.find(page => page.required_blocks_count));
    },
};
